/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import { useBio } from "~hooks";
import * as A from "~styles/Animations.jsx";
import Grid from "~styles/Grid.jsx";
import * as T from "~styles/Typography.jsx";
import { Image, LineSwipe, WaveCanvas } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as Close } from "~assets/svg/close.svg";

const Bio = () => {
  const { activeBio, setActiveBio, visible, setVisible } = useBio();

  //

  const close = () => {
    setActiveBio(null);
    setVisible(false);
  };

  //

  return (
    <div
      css={[
        css`
          transition: transform 0.75s ${A.EASING_CUBIC};
          transform: translate3d(${visible ? `0` : `100vw`}, 0, 0);
        `,
        tw`w-screen h-screen fixed left-0 bottom-0 right-0 top-0 z-40 bg-grey flex flex-col items-start justify-center overflow-y-scroll`
      ]}
    >
      {activeBio && (
        <>
          <WaveCanvas />

          <Grid _css={[css``, tw`h-full z-20 pt-8 md:pt-16`]}>
            <div
              css={[
                css`
                  //
                `,
                tw`col-start-12 flex items-center justify-end`
              ]}
            >
              <button
                type="button"
                css={[
                  css`
                    ${MEDIA_QUERIES.hoverable} {
                      &:hover {
                        svg {
                          path {
                            fill: ${getColor(`blue-turq`)};
                          }
                        }
                      }
                    }
                  `,
                  tw`w-10 h-10 relative block`
                ]}
                onClick={() => close()}
              >
                <div
                  css={[
                    tw`w-full h-full relative flex items-center justify-center`
                  ]}
                >
                  <Close css={[tw`w-6 relative block`]} color="black" />
                </div>
              </button>
            </div>

            <header
              css={[
                css`
                  //
                `,
                tw`col-span-12 md:col-span-6 relative flex items-center mt-12 md:mt-4 mb-8`
              ]}
            >
              <T.Head _css={[css``]} font="4" level="2">
                Specialist team
              </T.Head>

              <div css={[tw`w-32 relative block ml-5`]}>
                <LineSwipe color="#84bbd9" delay={500} duration={2000} />
              </div>
            </header>

            <div css={[tw`col-span-12 md:hidden mt-6 mb-8`]}>
              <figure
                css={[
                  css`
                    width: calc(100% + 4rem);
                    margin-left: -2rem;
                  `
                ]}
              >
                <Image image={activeBio.image} />
              </figure>
            </div>

            <div
              css={[
                css`
                  order: 2;

                  ${MEDIA_QUERIES.desktop} {
                    order: 1;
                  }
                `,
                tw`col-span-12 md:col-span-6 md:col-start-1 relative`
              ]}
            >
              <div css={[tw`w-full hidden md:block mb-8`]}>
                <figure
                  css={[
                    css`
                      width: 100%;
                      margin-left: auto;
                    `
                  ]}
                >
                  <Image image={activeBio.image} />
                </figure>
              </div>

              <T.Head
                _css={[tw`mb-5 font-bold text-blue-deep`]}
                font="3"
                level="3"
              >
                Areas of specialisation
              </T.Head>

              <T.Body _css={[tw`mb-24 md:mb-4`]} font="2">
                {activeBio.specialisation}
              </T.Body>
            </div>

            <div
              css={[
                css`
                  order: 1;

                  ${MEDIA_QUERIES.desktop} {
                    order: 2;
                  }
                `,
                tw`col-span-12 md:col-span-5 md:col-start-8 relative mb-16`
              ]}
            >
              <div css={[tw`relative mb-10 md:mb-16 border-b`]}>
                <T.Head _css={[tw`mb-3 md:mb-4`]} font="4" level="3">
                  {activeBio.title} {activeBio.name} {activeBio.surname}
                </T.Head>

                <T.Body _css={[tw`mb-4`]} font="3">
                  {activeBio.qualifications}
                </T.Body>
              </div>

              <T.Head
                _css={[tw`mb-12 font-bold text-blue-deep`]}
                font="4"
                level="3"
              >
                {activeBio.header}
              </T.Head>

              <T.Body font="2">{activeBio.body}</T.Body>
            </div>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Bio;
